import React, { useCallback, useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    makeStyles,
    Tooltip,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { toggleSnackbar } from "../../redux/explorer";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import Auth from "../../middleware/Auth";
// import API from "../../middleware/Api";
import API from "../../middleware/MasterApi"
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: theme.spacing(2),
    },
}));

export default function ToIdentify(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const user = Auth.GetUser();
    const [force, setForce] = useState(false);
    const [unlink, setUnlink] = useState(false);

    const dispatch = useDispatch();
    const ToggleSnackbar = useCallback(
        (vertical, horizontal, msg, color) =>
            dispatch(toggleSnackbar(vertical, horizontal, msg, color)),
        [dispatch]
    );

    const imgUrl = '/v3/file/preview/'

    ///api/v3/file/thumb/36xS8
    const submit = (e) => {
        e.preventDefault();
        props.setModalsLoading(true);
        const dirs = [],
            items = [];
        // eslint-disable-next-line
        props.selected.map((value) => {
            dirs.push(imgUrl+value.id);
        });

        // console.log(props.selected,'props.selected.map')

        API.post("/setCatchImage", {
            images:[...dirs]
        })
            .then((response) => {
                console.log(response,'response')
                if (response.rawData.success) {
                    props.onClose();
                    // iframe使用, 通知父窗口跳转
                    window.parent.postMessage({ type: "navigate", route: `/panel/ebayIdentify?id=${response.rawData.data.id}` }, '*');
                }
                props.setModalsLoading(false);
            })
            .catch((error) => {
                ToggleSnackbar("top", "right", error.message, "error");
                props.setModalsLoading(false);
            });
    };

    const classes = useStyles();

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {t("modals.transferToTitle")}
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {props.selected.length === 1 && (
                        <Trans
                            i18nKey="modals.transferOneDescription"
                            values={{
                                name: props.selected[0].name,
                            }}
                            components={[<strong key={0} />]}
                        />
                    )}
                    {props.selected.length > 1 &&
                        t("modals.transferMultipleDescription", {
                            num: props.selected.length,
                        })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    {t("cancel", { ns: "common" })}
                </Button>
                <div className={classes.wrapper}>
                    <Button
                        onClick={submit}
                        color="primary"
                        disabled={props.modalsLoading}
                    >
                        {t("ok", { ns: "common" })}
                        {props.modalsLoading && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
